// import React, { useEffect, useState } from 'react';
// import Alert from 'react-bootstrap/Alert';
// import Button from 'react-bootstrap/Button';


// import "./ContactUs.css"

// const ContactUs = () => {
//   const [show, setShow] = useState(false);
//   const [name, setNAme] = useState();
//   const [email, setEmail] = useState();
//   const [subject, setSubject] = useState();
//   const [message, setMessage] = useState();
//   const handle=()=>{
//     setShow(false)
//     setNAme("")
//     setEmail("")
//     setSubject("")
//     setMessage("")

//   }
//   useEffect(() => {
 
//   }, [show])
  



        
//   return (
//     <div className='contact'>
      

//     <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/mpznvwre" method="post">
//     <h3> Contact Us</h3>
//     <fieldset id="fs-frm-inputs">
   
   
//     <input type="text" name="name" id="full-name" placeholder="Enter your Full name" required="" value={name}/>
   
//     <input type="email" name="email" id="email-address" placeholder="Enter your email" required="" value={email}/>
   
//     <input type="text" name="subject" id="email-subject"  placeholder="Subject" required="" value={subject}/>

//     <textarea rows="5" name="message" id="message" placeholder="Message" required="" value={message}></textarea>
//     </fieldset>
//   <input type="submit" value="Submit"  id="input-submit"  onClick={() => setShow(true) } ></input>
//   </form>
//   <div className='alert'>
//         <Alert show={show} variant="success">
//         <Alert.Heading >Thank you!</Alert.Heading>
//         <p>
//         Your message has been sent.
//         </p>
//         <hr />
//         <div className="d-flex justify-content-end">
//           <Button onClick={() => handle()} variant="outline-success">
//             Close me
//           </Button>
//         </div>
//       </Alert>
//       </div>


//   </div>
//   );
   

  
// }

// export default ContactUs


import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './ContactUs.css';
import { Card } from 'react-bootstrap';


function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

const images = importAll(require.context('../../public/images', false, /\.(png|jpe?g|svg)$/));
// 
const Contact = () => {
    return (
        <div className="contact-container">
           <div className="container ">
      <div className="card">
      <Card>
        <Card.Img variant="top" style={{ height: '25rem' }} src={images['contact.jpg']} />
        <Card.Body>
          <Card.Title style={{fontSize:"30px"}}>
          C'est l'endroit idéal
          </Card.Title>
          <Card.Text  style={{fontSize:"20px"}}>
          Contactez-nous
          </Card.Text>
        </Card.Body>
      </Card>


      </div>
    </div>
            <div className="contact-info">
                <div className="contact-card">
                    <FaPhone className="contact-icon" />
                    <h3>Téléphone</h3>
                    <p>+216 74 433 020</p>
                </div>
                <div className="contact-card">
                    <FaEnvelope className="contact-icon" />
                    <h3>Email</h3>
                    <p>gm.polysoftco@gmail.com</p>
                </div>
                <div className="contact-card">
                    <FaMapMarkerAlt className="contact-icon" />
                    <h3>Adresse</h3>
                    <p>Rte de Teniour Km 1,5 / Sfax, Tunisia, 3000</p>
                </div>
            </div>
            <div className="contact-form">
                <h2>Contactez-Nous</h2>
                <form>
                    <input type="text" placeholder="Nom et prénom" />
                    <input type="email" placeholder="Email" />
                    <input type="text" placeholder="Sujet" />
                    <textarea placeholder="Message" />
                    <button type="submit" className="btn-submit">Envoyer</button>
                </form>
            </div>
        </div>
    );
};

export default Contact;
