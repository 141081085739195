// import React, { useEffect, useState } from 'react';
// import { Navbar, Container, Nav, Form, FormControl, Button } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { logout, getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../redux/action';
// import './CustomNavbar.css'; // Import the custom CSS file
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
// import { faSignInAlt, faUserPlus, faHome } from '@fortawesome/free-solid-svg-icons'; // Importez les icônes nécessaires depuis FontAwesome

// const CustomNavbar = ({ searching, handleSearch }) => {
//   const dispatch = useDispatch();
//   const { loading, client } = useSelector((state) => state.client);
//   const { families, sousfamilies } = useSelector(state => state.article);
//   const { cartItems } = useSelector((state) => state.cart);

//   const [selectedFamily, setSelectedFamily] = useState('');
//   const [selectedSousFamily, setSelectedSousFamily] = useState('');

//   useEffect(() => {
//     dispatch(getAllFamProducts());
//   }, [dispatch]);

//   const handleFamilyChange = (event) => {
//     const familyCode = event.target.value;
//     setSelectedFamily(familyCode);
//     setSelectedSousFamily('');
//     dispatch(getArticlesByFamily(familyCode));
//     dispatch(getBySousFamArt(familyCode));
//   };

//   const handleSousFamilyChange = (event) => {
//     const sousFamilyCode = event.target.value;
//     setSelectedSousFamily(sousFamilyCode);
//     dispatch(getArticlesByFamArtAndSousFam(selectedFamily, sousFamilyCode));
//   };

//   return (
//     <Navbar expand="lg" className="bg-body-tertiary"  variant="dark">
//       <Container fluid>
//         <Navbar.Brand href="/" className='ecomerce'><i class="fa-solid fa-austral-sign"></i> E-commerce</Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll">
//           <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '300px' }} navbarScroll>

          

            // <Form className="d-flex align-items-center">
            //   <label htmlFor="family-select" className="me-2"></label>
            //   <Form.Select
            //     id="family-select"
            //     className="me-4"
            //     value={selectedFamily}
            //     onChange={handleFamilyChange}
            //   >
            //     <option value="">Catégories:</option>
            //     {families.map((family, index) => (
            //       <option key={index} value={family.code}>
            //         {family.lib}
            //       </option>
            //     ))}
            //   </Form.Select>

            //   {selectedFamily && (
            //     <Form.Select
            //       id="sousfamily-select"
            //       className="me-2"
            //       value={selectedSousFamily}
            //       onChange={handleSousFamilyChange}
            //     >
            //       <option value="">Sous-catégorie:</option>
            //       {sousfamilies.map((sousfamily, idx) => (
            //         <option key={idx} value={sousfamily.lib}>
            //           {sousfamily.lib}
            //         </option>
            //       ))}
            //     </Form.Select>
            //   )}
            // </Form>

            
//             <Form className="d-flex" id="search">
//               <FormControl
//                 type="search"
//                 placeholder="Rechercher"
//                 className="me-2"
//                 aria-label="Search"
//                 value={searching}
//                 onChange={(e) => handleSearch(e.target.value)}
                
//               />
//               <Button style={{ color: "black", backgroundColor:"yellow", marginTop:"-5px"}} variant="outline-success" onClick={() => handleSearch(searching)}>
//               <i style={{ color: "black" }} className="fa-solid fa-magnifying-glass"></i>
//               </Button>
//             </Form>

//             {/* <Nav.Link onClick={() => dispatch(logout())}>Log Out</Nav.Link> */}
//                     {/* <Nav.Link href="/profile">{client.raisoc}</Nav.Link> */}
            
//                     <Nav.Link href="/">
//               <FontAwesomeIcon icon={faHome} /> Accueil
//             </Nav.Link>
            
//             {client ?  (<>
//             </>)
//             :
//            (<>
//             <Nav.Link href="/signup">
//               <FontAwesomeIcon icon={faUserPlus} /> Inscription
//             </Nav.Link>

//             <Nav.Link href="/login">
//               <FontAwesomeIcon icon={faSignInAlt} /> Connexion
//             </Nav.Link>
//               </>
            
//           )
//             }
//             { client ?
//                (<Nav.Link onClick={() => dispatch(logout())}>
//               <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
//             </Nav.Link>)
//             :
//             (<></>)
//             }
          
//             {
//               loading ? <Nav.Link>Loading ...</Nav.Link> : <div>{client && <Nav.Link href="/profile">{client.raisoc}<i className="fas fa-user"></i></Nav.Link>}</div>
//             }


//           </Nav>

//           <Nav>
//             <div className='btnCartCount'>
//               <div className='count'>{Object.keys(cartItems).length}</div>
//               <Nav.Link href='/cart'>
//                 <i className='fas fa-shopping-cart'></i>
//               </Nav.Link>
//             </div>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default CustomNavbar;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { logout, getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../../redux/action';
import './CustomNavbar.css'; // Importez le fichier CSS personnalisé
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faShoppingCart, faMapMarkerAlt, faPhone, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { assets, food_list } from "../../assets/assets";
import Login from '../Login';
const CustomNavbar = ({ searching, handleSearch }) => {
  const dispatch = useDispatch();
  const { client,loading } = useSelector((state) => state.client);
  const { families, sousfamilies } = useSelector(state => state.article);
  const { cartItems } = useSelector((state) => state.cart);
  const [menu, setMenu] = useState("menu");
  const [selectedFamily, setSelectedFamily] = useState('');
  const [selectedSousFamily, setSelectedSousFamily] = useState('');

  useEffect(() => {
    dispatch(getAllFamProducts());
  }, [dispatch]);

  const handleFamilyChange = (event) => {
    const familyCode = event.target.value;
    setSelectedFamily(familyCode);
    setSelectedSousFamily('');
    dispatch(getArticlesByFamily(familyCode));
    dispatch(getBySousFamArt(familyCode));
  };

  const handleSousFamilyChange = (event) => {
    const sousFamilyCode = event.target.value;
    setSelectedSousFamily(sousFamilyCode);
    dispatch(getArticlesByFamArtAndSousFam(selectedFamily, sousFamilyCode));
  };

  return (
  
        
    <Navbar expand="lg" className="custom-navbar" >
      <Container fluid>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       {/* <Navbar.Brand href="/" className='ecomerce'> E-commerce</Navbar.Brand>
       &nbsp;&nbsp;  */}
      <Navbar.Brand href="/"  className="navbar">
        <img src={assets.logo} alt="" className="logo" />  
         {/* <img src="../images/logopoly.png" alt="" style={{width:"80px", height:"45px"}}  /> */}
      </Navbar.Brand>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* Menu déroulant pour les catégories */}
        <Form className="d-flex align-items-center">
          <Form.Select
            id="family-select"
            // className="me-4"
            className="me-4 t"
            value={selectedFamily}
            onChange={handleFamilyChange}
          >
            <option value="">Catégories:</option>
            {families.map((family, index) => (
              <option key={index} value={family.code}>
                {family.lib}
              </option>
            ))}
          </Form.Select>

          {/* Menu déroulant pour les sous-catégories */}
          {selectedFamily && (
            <Form.Select
              id="sousfamily-select"
              // className="me-2"
              className="me-4 t"
              value={selectedSousFamily}
              onChange={handleSousFamilyChange}
            >
              <option value="">Sous-catégorie:</option>
              {sousfamilies.map((sousfamily, idx) => (
                <option key={idx} value={sousfamily.lib}>
                  {sousfamily.lib}
                </option>
              ))}
            </Form.Select>
          )}
        </Form>
       
        {/* Barre de recherche */}
        <Form className="d-flex search-bar">
          <FormControl
            type="search"
            placeholder="Rechercher..."
            className="me-2 search-input t"
            aria-label="Search"
            value={searching}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button 
          // variant="danger" 
          variant="outline-danger"
          className="search-button"
          // className="navbar-search-icon"
           onClick={() => handleSearch(searching)}>
            <FontAwesomeIcon icon={faSearch} />
            
          </Button>
        </Form>
        {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
        {/* Liens à droite de la barre de navigation */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="me-auto">
            <NavLink to="/cart" className="nav-icon" style={{color: "#2f3235",
       
    display: "block",
    fontsize: "16 px",
    fontweight: "var(--bs-nav-link-font-weight)",
    padding:" var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)",
    textdecoration: "none",
    transition: "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out"}}>
            {/* <Nav.Link href="/cart" className="nav-icon" > */}
              <FontAwesomeIcon icon={faShoppingCart}  style={{ fontSize: "20px" }}/> <span className="cart-count">{Object.keys(cartItems).length}</span>
            {/* </Nav.Link> */}
            </NavLink>
            <Nav.Link href="https://www.google.com/maps/place/POLYSOFT/@34.753891,10.758659,19z/data=!4m6!3m5!1s0x1301d3fcbdf5f629:0x2fe90eeb77a94e4b!8m2!3d34.753796!4d10.7584861!16s%2Fg%2F11hnpsh09s?entry=ttu" className="nav-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: "20px" }} />
            </Nav.Link>
            <Nav.Link href="tel:74 442 637" className="nav-icon">
              <FontAwesomeIcon icon={faPhone} /> 74 442 637
            </Nav.Link>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
            <NavLink to="/"   
            onClick={() => setMenu("home")}
            className={({ isActive }) => (isActive || menu === "home" ? "active" : "")}
        // className={menu === "home" ? "active" : ""}
          style={{color:  "#2f3235",
            //"rgb(217 17 17 / 55%)",
    display: "block",
    fontSize:"20px",
    fontweight: "var(--bs-nav-link-font-weight)",
    padding:" var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)",
    textDecoration: "none",
    transition: "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out"}}>
         Home
       </NavLink>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <NavLink to=""  
        onClick={() => setMenu("menu")}
        //  className={menu == "menu" ? "active" : ""}
        className={({ isActive }) => (isActive || menu === "menu" ? "active" : "")}
          style={{color:  "#2f3235",
            // "rgb(217 17 17 / 55%)",
    display: "block",
    fontSize:"20px",

    fontweight: "var(--bs-nav-link-font-weight)",
    padding:" var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)",
    textDecoration: "none",
    transition: "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out"}}>
         Menu
       </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {/* <Nav.Link href="/"   onClick={() => setMenu("home")}
          className={menu === "home" ? "active" : ""}>
         Home
       </Nav.Link>
       <Nav.Link href=""  
        onClick={() => setMenu("menu")}
          className={menu === "menu" ? "active" : ""}>
         Menu
       </Nav.Link> */}
       {/* <Nav.Link
        href="/contact"
        onClick={() => setMenu("contact")}
        className={menu === "contact" ? "active" : ""}
       >
          Contact Us
       </Nav.Link>
      */}
      <NavLink
  to="/contact"
  onClick={() => setMenu("contact")}
  className={({ isActive }) => (isActive || menu === "contact" ? "active" : "")}
  // className={menu === "contact" ? "active" : ""}
  
  style={{color:  "#2f3235",
    // "rgb(217 17 17 / 55%)",
    display: "block",
    fontSize:"20px",
    fontweight: "var(--bs-nav-link-font-weight)",
    padding:" var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)",
    textDecoration: "none",
    transition: "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out"}}
    

>
  Contact Us
</NavLink>
  
            <div className="nav-icon welcome">
              Bienvenue  {
               loading ? <Nav.Link>Loading ...</Nav.Link> : <div>{client && <Nav.Link href="/profile">{client.raisoc}<i className="fas fa-user"></i></Nav.Link>}</div>
           }
            </div>
            {client ? (
              <Nav.Link onClick={() => dispatch(logout())} className="me-2 t"  >
                <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
              </Nav.Link>
            ) : (
              <>
                {/* <Nav.Link href="/Login" className="me-2 t">
                  Connexion
                </Nav.Link> */}
                {/* <Nav.Link href="/signup" className="nav-icon" style={{marginLeft:"-10px"}}>
                  Créer un compte
                </Nav.Link> */}
              </>
            )}
            
          </Nav>

          {/* Bienvenue message */}
          <Nav>
            
           
          </Nav>
        </Navbar.Collapse>
      
      </Container>
      
    </Navbar>
  
  );
};

export default CustomNavbar;
