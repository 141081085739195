import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { faShoppingBag,faCartShopping  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import'./article/List.css';
import { assets, food_list } from "../assets/assets";
const CardArticle = ({ article }) => {

  // const [result, setResult] = useState();
  const [photoSrc, setPhotoSrc] = useState('');

  useEffect(() => {
    if (article?.Photo) {
      // console.log('Binary photo data:', article.desart+"             " +article.Photo); // Vérifiez ici si les données binaires sont présentes
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(article.Photo))
      );
      setPhotoSrc(`data:image/jpeg;base64,${base64String}`);
    }
  }, [article]);


  return (
    <div className="food-display" id="food-display">
  
      <NavLink to={`/getArticleDetail/${article.codart}`}  className="no-link ">
      <div className='food-item'> 
      <div className='food-item-img-container'>
        <img className='food-item-image' src={`data:image/*;base64,${article?.Photo}`} alt=''style={{ height: '15rem' }} />
     
     
          <div className='food-item-desc'>
        
            {article.desart} 
           
             <img src={assets.rating_starts} alt='' />
          
          </div>
         
            Couleur: 
            <Box component="span" sx={{
              display: 'inline-block',
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor: article.chdec,
              ml: 1
            }} />
      
          <div className='food-item-price-cart'>
          <p className='food-item-price'>
          {` ${article.PUDTTC} DT`}</p></div>
       

        
          {(article.stkfin > 1) ? (
        <div className="label-container">
          <div className="label-position-right">
            <div className="label-line">
              <div className="label-inline">
                <div className="stock-label">
                  <span>
                    <b>EN STOCK</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="label-container">
          <div className="label-position-right">
            <div className="label-line">
              <div className="label-inline">
                <div className="out-of-stock-label">
                  <span>
                    <b>HORS STOCK</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        
       
    
        </div></div>
      </NavLink>
 
      
      <div className="card-icon">

  <NavLink to={`/getArticleDetail/${article.codart}`} className="no-link">
  <FontAwesomeIcon icon={faCartShopping} className="icon" />
  </NavLink>
</div>

    </div>
  )
}

export default CardArticle;
