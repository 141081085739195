import React, { useState } from 'react';
import { Carousel,Nav, Col, Container, Row } from 'react-bootstrap';

import { FaShippingFast, FaGift, FaHeadset, FaTags, FaCreditCard } from 'react-icons/fa';
import ArticleCard from './CardArticle';
import ListFamProd from './listeFam/ListFamProd';
import Paging from './Paging'; // Make sure to import your custom Paging component
import'./Header.css';
import'./article/List.css';
import Footer from './Footer';

const ListArticle = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;

  if (!articles || articles.length === 0) {
    return <div>No articles available.</div>;
  }

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const currentArticles = [];
  for (let i = indexOfFirstArticle; i < indexOfLastArticle; i++) {
    if (i >= 0 && i < articles.length) {
      currentArticles.push(articles[i]);
    }
  }
  // Importation des images
  function importAll(r) {
    let images = {};
    r.keys().map((item) => {
      images[item.replace('./', '')] = r(item);
      return null;
    });
    return images;
  }

  const images = importAll(require.context('../../public/images', false, /\.(png|jpe?g|svg)$/));

  // Calcul des articles à afficher
  // const indexOfLastArticle = currentPage * articlesPerPage;
  // const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  // //const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
  // const currentArticles = [];
  // for (let i = indexOfFirstArticle; i < indexOfLastArticle; i++) {
  //   if (i >= 0 && i < articles.length) {
  //     currentArticles.push(articles[i]);
  //   }
  // }
  
  // Callback when page changes
  const onPageChanged = (data) => {
    setCurrentPage(data.currentPage);
  };

  return (
    <div>
    

      <div className="carousels" style={{ marginTop: '-20px' }}>
        {/* <Carousel variant="dark" fade>
          <Carousel.Item style={{ color: 'black' }}>
            <img
              className="d-block w-100"
              style={{ height: '80vh', opacity: '0.9' }}
              src={images['mencl.jpg']}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 style={{ fontSize: '28px', color: 'black', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                Men's clothing
              </h3>
              <p style={{ fontSize: '22px', color: 'black' }}>
                Shop for our collection of men's clothing including suits, dress shirts, sportcoats, big & tall & custom
                clothing online
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              style={{ height: '80vh', opacity: '0.8' }}
              src={images['woo.png']}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 style={{ fontSize: '28px', color: 'black', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                Women's clothing
              </h3>
              <p style={{ fontSize: '22px', color: 'black' }}>
                Shop for our collection of women's clothing including suits, dress shirts, sportcoats, big & tall & custom
                clothing online
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
         <div className="header" border-radius= "500px">
      <div className="header-contents">
      {/* <h1 class="entry-title">E-commerce quel impact sur la   société d’aujourd’hui?</h1> */}
        <h3> E-commerce quel impact sur la   société d’aujourd’hui?</h3>
        <p>Le e-commerce ou le commerce en ligne prend de plus en plus de place dans la société d’aujourd’hui. En effet, les personnes préfèrent commander en ligne de par la tranquillité et la facilité. Alors quels sont les aspects positifs et négatifs du commerce en ligne? Quel est l’impact de la pandémie du covid19 sur le e-commerce et qu’elle sera l’avenir de ce commerce?</p>
        <button > View Menu</button>
        {/* <Nav.Link href="/ListArticle" className="me-2 t"> View Menu</Nav.Link> */}
     </div> 
   </div>
      
      </div>
  <ListFamProd />
  <div className="food-display" id="food-display">
      <Container>
                          <Container className="mt-5">
                            <Row>
                              <Col className="text-center">
                                <FaShippingFast size={50} />
                                <h5>Livraison gratuite</h5>
                                <p>De toutes les commandes de plus de 5 dt</p>
                              </Col>
                              <Col className="text-center">
                                <FaGift size={50} />
                                <h5>Offres surprises quotidiennes</h5>
                                <p>Économisez jusqu'à 25 % de réduction</p>
                              </Col>
                              <Col className="text-center">
                                <FaHeadset size={50} />
                                <h5>Assistance 24h/24 et 7j/7</h5>
                                <p>Achetez avec un expert</p>
                              </Col>
                              <Col className="text-center">
                                <FaTags size={50} />
                                <h5>Prix abordables</h5>
                                <p>Obtenir le prix d'usine par défaut</p>
                              </Col>
                              {/* <Col className="text-center">
                                <FaCreditCard size={50} />
                                <h5>Paiements sécurisés</h5>
                                <p>Paiement 100% protégé</p>
                              </Col> */}
                            </Row>
                          </Container>
                        
                          {/* <Row> */}
                            <h2>
                              Collection en vedette
                            </h2>
                            {/* <div className="food-display-list">
                              

                          
                                {currentArticles.map((el) => (
                                
                                  <ArticleCard article={el} /> 
                                
                                ))}
                             
                            </div> */}
                  <div className="food-display-list">
                    {currentArticles.map((el, index) => (
                      <section key={index} className="food-display-list">
                        <ArticleCard article={el} />
                      </section>
                    ))}
                  </div>
           
                          <Row>
                            <Col className="d-flex justify-content-center mt-4">
                              <Paging
                                totalRecords={articles.length}
                                pageLimit={articlesPerPage}
                                pageNeighbours={2}
                                onPageChanged={onPageChanged}
                                sizing="pagination-lg"
                                alignment="justify-content-center"
                              />
                            </Col>
                          </Row>
      </Container> 
      </div>
      <Footer />
    </div>
  );
};

export default ListArticle;
